var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      ref: "couponCard",
      staticClass: "welcome-coupon-card rounded-lg d-flex pa-1",
      class: _vm.type,
      attrs: { elevation: "2", disabled: _vm.disabled },
      on: { click: _vm.showDetails }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center align-center flex-shrink-1" },
        [
          _c("img", {
            staticClass: "coupon-img rounded-lg",
            attrs: {
              src:
                _vm.type == "activeCashbackDiscount"
                  ? _vm.coupon.product.mediaURL
                  : _vm.coupon.mediaURL
                  ? _vm.coupon.mediaURL
                  : _vm.coupon.media[0].small,
              onerror: "this.onerror=null;this.src='/no-icon.png'",
              alt:
                "Coupon-" +
                (_vm.type == "activeCashbackDiscount"
                  ? _vm.coupon.barCode
                  : _vm.coupon.name)
            }
          })
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column justify-center text-center w-100 ml-2 px-1"
        },
        [
          _c("div", { staticClass: "coupon-description" }, [
            _c(
              "div",
              { staticClass: "font-weight-semibold text-body-2 pt-1" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("coupons.discounts.discountCoupon")) + " "
                )
              ]
            ),
            _vm.type == "activeCashbackDiscount"
              ? _c(
                  "div",
                  { staticClass: "amount", class: `${_vm.type + "-name"}` },
                  [_vm._v(" " + _vm._s(_vm.coupon.product.name) + " ")]
                )
              : _c(
                  "div",
                  { staticClass: "amount", class: `${_vm.type + "-name"}` },
                  [_vm._v(" " + _vm._s(_vm.coupon.name) + " ")]
                ),
            _vm.type == "activeCashbackDiscount"
              ? _c(
                  "div",
                  {
                    staticClass: "font-weight-regular text-body-3 pb-1",
                    class: `${_vm.type + "-name"}`
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("coupons.discounts.endDate", {
                            endDate: _vm.coupon.endRetreat
                          })
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "flex-grow-1 d-flex align-center" },
            [
              _vm.type == "activeCashbackDiscount"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        color: "primary",
                        block: "",
                        large: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.showDetails.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("coupons.discounts.view")))]
                  )
                : _vm.type == "incomingDiscount"
                ? _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("coupons.discounts.availableNextWeek")) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }